.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.hydrated{
  background-color: rgb(20,84,46);
  justify-content:center;
  padding: 10px;
  margin: 10px;

}

.App-header {
  background-color: rgb(57,142,2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-body {
  background-color: rgb(57,142,2);
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #398e02;
}

.navbar{
    background-color: rgb(20,84,46) !important;
}
.navbar .navbar-brand{
    margin : 0 0;
}
.navbar .navbar-nav{
    margin :  0 auto;
    color : #FFF;
}
.navbar form button{
    color: rgb(57,142,2);
}
.navbar form button:hover {
    background-color: rgb(82,187,18);
}
.card {
  justify-content: center;
  width: 12rem;
  color: black;
  border-color: rgb(20,84,46);
  border-width: 4px;
  border-style: solid;
}
.card-body{
  background-color:white;
  text-align: center;
}
.card-title{
  color:#007bff;
}
.center{
  justify-content: space-between; 
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
